var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',[_c('div',{staticClass:"showPhone"},[_c('div',{staticClass:"phoneBox",style:({ '--bg': ("url(" + _vm.imgUrlHead + "phonebg.png)") })},[_c('div',{staticClass:"topFixed-top"},[_c('div',[_vm._l((4),function(item,index){return _c('i',{key:index,staticClass:"iconfont icon-dian"})}),_c('i',{staticClass:"iconfont icon-iconhuizong_huaban1fuben3"})],2),_c('div',[_vm._v("9.00AM")]),_c('div',[_vm._v(" 100% "),_c('i',{staticClass:"iconfont icon-dianchi",staticStyle:{"font-size":"22px"}})])]),_c('div',{staticClass:"showContent"},[_c('div',{on:{"click":function () { return (_vm.updateName = '轮播图'); }}},[_c('el-carousel',{attrs:{"interval":2000,"type":"card","height":"150px"}},_vm._l((_vm.imgList),function(item,index){return _c('el-carousel-item',{key:index},[_c('img',{staticClass:"medium",attrs:{"src":item.img,"alt":""}})])}),1)],1),_c('div',{staticClass:"notice",style:({ backgroundImage: ("url(" + (_vm.imgUrlHeadS + 'noticeBg.png') + ")") }),on:{"click":function () { return (_vm.updateName = '公告栏'); }}},[_vm._v(_vm._s(_vm.notice[0].content))]),_c('img',{staticClass:"footer",attrs:{"src":_vm.imgUrlHeadS + 'modalType.png',"alt":""}})])])]),_c('div',{staticClass:"controlBox"},[_c('div',{staticClass:"topName"},[_vm._v(_vm._s(_vm.updateName))]),(_vm.updateName === '轮播图')?_c('div',{staticClass:"content"},[_c('p',{staticClass:"tips"},[_vm._v("* 建议尺寸 694px * 300px,支持png、bmg、jpeg、jpg、gif")]),_c('div',{staticClass:"listItem"},[_c('vuedraggable',_vm._b({model:{value:(_vm.imgList),callback:function ($$v) {_vm.imgList=$$v},expression:"imgList"}},'vuedraggable',{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true,
          },false),[_c('transition-group',_vm._l((_vm.imgList),function(item,index){return _c('div',{key:index,staticClass:"itemBox"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"leftL"},[_c('span',{class:index == 0 ? 'fcccc' : '',on:{"click":function($event){return _vm.moveClick(0, index, item)}}},[_vm._v("<")]),_c('span',{class:index == _vm.imgList.length - 1 ? 'fcccc' : '',on:{"click":function($event){return _vm.moveClick(1, index, item)}}},[_vm._v(">")])]),_c('div',{staticClass:"leftR"},[_c('img',{attrs:{"src":item.img,"alt":""}})])]),_c('div',{staticClass:"right"},[_c('div',[_c('div',{staticClass:"right-top"},[_c('div',{staticClass:"inputBox"},[_c('el-input',{attrs:{"disabled":"","placeholder":"选择图片"},model:{value:(item.img),callback:function ($$v) {_vm.$set(item, "img", $$v)},expression:"item.img"}}),_c('div',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.chooseImg(index)}}},[_vm._v("选择")])],1)],1)]),_c('div',{staticClass:"right-top"},[_c('div',{staticClass:"inputBox"},[_c('el-input',{attrs:{"disabled":""},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.choosePage(index)}}},[_vm._v("选择")])],1)])]),_c('div',{on:{"click":function($event){return _vm.deleteItem(index)}}},[(_vm.imgList.length > 1)?_c('i',{staticClass:"iconfont icon-guanbi"}):_vm._e()])])])}),0)],1)],1),_c('div',{staticClass:"addOne",on:{"click":_vm.addBanner}},[_vm._v("新增一个")])]):_vm._e(),(_vm.updateName === '公告栏')?_c('div',{staticClass:"content"},[_c('vuedraggable',_vm._b({model:{value:(_vm.notice),callback:function ($$v) {_vm.notice=$$v},expression:"notice"}},'vuedraggable',{
          animation: 200,
          group: 'description',
          disabled: false,
          ghostClass: 'ghost',
          scroll: true,
        },false),[_c('transition-group',_vm._l((_vm.notice),function(item,index){return _c('div',{key:index,staticClass:"noticeitem"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"left-top"},[_c('p',[_vm._v("公告标题")]),_c('el-input',{model:{value:(item.content),callback:function ($$v) {_vm.$set(item, "content", $$v)},expression:"item.content"}})],1),_c('div',{staticClass:"left-bot"},[_c('el-input',{attrs:{"disabled":""},model:{value:(item.path),callback:function ($$v) {_vm.$set(item, "path", $$v)},expression:"item.path"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.choosePage(index)}}},[_vm._v("选择")])],1)]),_c('div',{staticClass:"right"},[(_vm.notice.length > 1)?_c('i',{staticClass:"iconfont icon-guanbi",on:{"click":function($event){return _vm.deleteNoticeItem(index)}}}):_vm._e()])])}),0)],1),_c('div',{staticClass:"addOne",on:{"click":_vm.addNotice}},[_vm._v("新增一个")])],1):_vm._e()]),_c('el-dialog',{attrs:{"title":"选择链接","visible":_vm.toPageFlag,"width":"52%"},on:{"update:visible":function($event){_vm.toPageFlag=$event}}},[_c('div',[_c('ToPage',{ref:"toPage",on:{"getPage":_vm.getPageRes}})],1)]),(_vm.chooseImgFlag)?_c('UploadSource',{attrs:{"dialogVisible":_vm.chooseImgFlag,"maxSize":1024 * 1024},on:{"changeStatus":_vm.changeStatus,"getSource":_vm.getImgRes}}):_vm._e(),_c('Preservation',{on:{"preservation":_vm.Preservation}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <el-main>
    <div class="showPhone">
      <div class="phoneBox" :style="{ '--bg': `url(${imgUrlHead}phonebg.png)` }">
        <!-- 手机顶部小图标栏 -->
        <div class="topFixed-top">
          <div>
            <i class="iconfont icon-dian" v-for="(item, index) in 4" :key="index"></i>
            <i class="iconfont icon-iconhuizong_huaban1fuben3"></i>
          </div>
          <div>9.00AM</div>
          <div>
            100%
            <i class="iconfont icon-dianchi" style="font-size: 22px"></i>
          </div>
        </div>
        <!-- <div class="header">首页</div> -->
        <div class="showContent">
          <div @click="() => (updateName = '轮播图')">
            <el-carousel :interval="2000" type="card" height="150px">
              <el-carousel-item v-for="(item, index) in imgList" :key="index">
                <img class="medium" :src="item.img" alt="" />
              </el-carousel-item>
            </el-carousel>
          </div>

          <div class="notice" @click="() => (updateName = '公告栏')" :style="{ backgroundImage: `url(${imgUrlHeadS + 'noticeBg.png'})` }">{{ notice[0].content }}</div>
          <img class="footer" :src="imgUrlHeadS + 'modalType.png'" alt="" />
        </div>
      </div>
    </div>
    <div class="controlBox">
      <div class="topName">{{ updateName }}</div>
      <div class="content" v-if="updateName === '轮播图'">
        <p class="tips">* 建议尺寸 694px * 300px,支持png、bmg、jpeg、jpg、gif</p>
        <div class="listItem">
          <vuedraggable
            v-model="imgList"
            v-bind="{
              animation: 200,
              group: 'description',
              disabled: false,
              ghostClass: 'ghost',
              scroll: true,
            }"
          >
            <transition-group>
              <div class="itemBox" v-for="(item, index) in imgList" :key="index">
                <div class="left">
                  <div class="leftL">
                    <span @click="moveClick(0, index, item)" :class="index == 0 ? 'fcccc' : ''">&lt;</span>
                    <span @click="moveClick(1, index, item)" :class="index == imgList.length - 1 ? 'fcccc' : ''">></span>
                  </div>
                  <div class="leftR">
                    <img :src="item.img" alt="" />
                  </div>
                </div>
                <div class="right">
                  <div>
                    <div class="right-top">
                      <div class="inputBox">
                        <el-input v-model="item.img" disabled placeholder="选择图片"></el-input>
                        <div>
                          <el-button type="primary" @click="chooseImg(index)">选择</el-button>
                        </div>
                      </div>
                    </div>
                    <div class="right-top">
                      <div class="inputBox">
                        <el-input v-model="item.path" disabled></el-input>
                        <el-button type="primary" @click="choosePage(index)">选择</el-button>
                      </div>
                    </div>
                  </div>
                  <div @click="deleteItem(index)">
                    <i v-if="imgList.length > 1" class="iconfont icon-guanbi"></i>
                  </div>
                </div>
              </div>
            </transition-group>
          </vuedraggable>
        </div>
        <div @click="addBanner" class="addOne">新增一个</div>
      </div>
      <div class="content" v-if="updateName === '公告栏'">
        <vuedraggable
          v-model="notice"
          v-bind="{
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
            scroll: true,
          }"
        >
          <transition-group>
            <div class="noticeitem" v-for="(item, index) in notice" :key="index">
              <div class="left">
                <div class="left-top">
                  <p>公告标题</p>
                  <el-input v-model="item.content"></el-input>
                </div>
                <div class="left-bot">
                  <el-input disabled v-model="item.path"></el-input>
                  <el-button type="primary" @click="choosePage(index)">选择</el-button>
                </div>
              </div>
              <div class="right">
                <i v-if="notice.length > 1" class="iconfont icon-guanbi" @click="deleteNoticeItem(index)"></i>
              </div>
            </div>
          </transition-group>
        </vuedraggable>
        <div @click="addNotice" class="addOne">新增一个</div>
      </div>
    </div>
    <!-- 选择跳转页面弹框 -->
    <el-dialog title="选择链接" :visible.sync="toPageFlag" width="52%">
      <div>
        <ToPage ref="toPage" @getPage="getPageRes"></ToPage>
      </div>
    </el-dialog>
    <!-- 选择图片弹框 -->
    <UploadSource v-if="chooseImgFlag" @changeStatus="changeStatus" :dialogVisible="chooseImgFlag" @getSource="getImgRes" :maxSize="1024 * 1024"></UploadSource>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config';
import Preservation from '@/components/preservation';
import vuedraggable from 'vuedraggable';
import UploadSource from '@/components/uploadSource';
import ToPage from '../../views/addPages/toPage/index.vue';
export default {
  components: {
    vuedraggable,
    UploadSource,
    ToPage,
    Preservation,
  },
  data() {
    return {
      imgUrlHead,
      imgUrlHeadS: imgUrlHead + 'E-sports/',
      active: 1,
      imgList: [
        { img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/sports/test.png', path: '' },
        { img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/sports/test.png', path: '' },
        { img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/sports/test.png', path: '' },
        { img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/sports/test.png', path: '' },
        { img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/sports/test.png', path: '' },
      ],
      notice: [
        {
          content: '这是一条公告',
          path: '',
        },
      ],
      updateName: '轮播图',
      toPageFlag: false,
      chooseImgFlag: false,
      chooseThatIndex: -1,
      type: -1,
    };
  },
  computed: {},
  created() {
    this.getDiyPage();
  },
  methods: {
    getDiyPage() {
      this.$axios.post(this.$api.sports.diypageList, { type: 1 }).then(res => {
        if (res.code === 0) {
          if (res.result.page_values) {
            this.imgList = res.result.page_values[0];
            this.notice = res.result.page_values[1];
            this.type = 1;
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    Preservation() {
      let obj = {
        page_name: '电竞首页',
        page_type: 1,
        show_type: '2,3,4,5,6,7',
        page_value: [this.imgList, this.notice],
      };
      if (this.type === 1) {
        obj.type = this.type;
      }
      this.$axios.post(this.$api.sports.diypage, obj).then(res => {
        if (res.code === 0) {
          this.$message.success('保存成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    changeStatus(val) {
      this.chooseImgFlag = val;
    },
    addBanner() {
      this.imgList.push({
        img: 'https://boweisou.oss-cn-shenzhen.aliyuncs.com/admin2.0/bannerDefault.png',
        path: '跳转页面',
      });
    },
    // 点击移动banner图位置
    moveClick(status, index, item) {
      if (status == 0) {
        // 向上移
        if (index == 0) {
          this.$message({
            message: '已经在最前面了~',
            type: 'error',
          });
          return;
        }
        this.imgList.splice(index, 1);
        this.imgList.splice(index - 1, 0, item);
      } else {
        // 向下移
        if (index == this.imgList.length - 1) {
          this.$message({
            message: '已经在最后面了~',
            type: 'error',
          });
          return;
        }
        this.imgList.splice(index + 2, 0, item);
        this.imgList.splice(index, 1);
      }
    },
    // 删除对应banner图
    deleteItem(index) {
      this.imgList.splice(index, 1);
    },
    deleteNoticeItem(index) {
      this.notice.splice(index, 1);
    },
    // 选择图片
    chooseImg(index) {
      this.chooseImgFlag = true;
      this.chooseThatIndex = index;
    },
    // 选择跳转路径
    choosePage(index) {
      this.toPageFlag = true;
      this.chooseThatIndex = index;
      this.$nextTick(() => {
        this.$refs.toPage.getPageArr();
      });
    },
    // 拿到跳转路径
    getPageRes(item) {
      if (this.updateName === '轮播图') {
        this.imgList[this.chooseThatIndex].path = item.wxroute;
      } else {
        this.notice[this.chooseThatIndex].path = item.wxroute;
      }

      this.toPageFlag = false;
    },
    // 拿到图片
    getImgRes(imglist) {
      this.imgList[this.chooseThatIndex].img = imglist[0].path;
    },
    addNotice() {
      if (this.notice.length === 4) {
        this.$message.error('只可添加四条公告');
        return;
      }
      this.notice.push({
        content: '这是一条公告',
        path: '',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  display: flex;
  justify-content: center;
}
.showPhone {
  margin-right: 200px;
  .phoneBox {
    width: 409px;
    height: 770px;
    background-size: 100% 100%;
    margin-right: 20px;
    background-image: var(--bg);
    padding: 54px 20px 66px 16px;
    display: flex;
    flex-direction: column;
    .topFixed-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      background-color: #d7d7d7;
      & > div {
        display: flex;
        align-items: center;
      }
      i {
        font-size: 12px;
      }
    }
    .header {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
    .showContent {
      flex: 1;
      width: 100%;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
      }
      .el-carousel {
        width: 192%;
        position: relative;
        left: -46%;
        margin-top: 10px;
        img {
          width: 100%;
          height: 100%;
        }
        /deep/ .el-carousel__indicators {
          .el-carousel__button {
            width: 8px;
            height: 8px;
            opacity: 1;
            border-radius: 50%;
            background-color: #fff;
            border: 1px solid #786fdf;
          }
          .is-active {
            .el-carousel__button {
              width: 18px;
              height: 8px;
              opacity: 1;
              border-radius: 4px;
              background-color: #786fdf;
              border: 1px solid #786fdf;
            }
          }
        }
      }
      .notice {
        width: calc(100% - 28px);
        height: 40px;
        line-height: 40px;
        background-size: 100% 100%;
        margin: 0 14px;
        padding-left: 60px;
        font-size: 14px;
        font-weight: bold;
        color: #090533;
      }
    }
  }
  .footer {
    width: 100%;
    margin-top: 20px;
  }
}
.controlBox {
  width: 570px;
  min-height: 200px;
  border-radius: 10px;
  border: 1px solid #ccc;
  .topName {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .content {
    padding: 20px;
    .tips {
      font-size: 14px;
      color: #9a9a9a;
    }
    .listItem {
      width: 100%;
      max-height: 555px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0px;
        height: 5px;
        /**/
      }
      .itemBox {
        width: 100%;
        display: flex;
        height: 100px;
        margin-top: 20px;
        .left {
          display: flex;
          .leftL {
            font-family: SimSun;
            width: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-right: 20px;

            span {
              width: 16px;
              height: 16px;
              line-height: 16px;
              text-align: center;
              border-radius: 5px;
              display: block;
              border: 1px solid #333;
              font-weight: bold;
              cursor: pointer;
            }
            span:first-child {
              transform: rotate(90deg);
            }
            span:last-child {
              transform: rotate(90deg);
              margin-top: 10px;
            }
          }
          .leftR {
            height: 100%;
            width: 220px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .right {
          flex: 1;
          display: flex;
          justify-content: space-around;
          align-items: center;
          & > div:first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .inputBox {
            width: 220px;
            height: 40px;
            border: 1px solid #ccc;
            border-radius: 3px;
            display: flex;
            align-items: center;
            position: relative;
            p {
              width: 150px;
              margin-left: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              color: #9e9e9e;
            }
          }
        }
      }
    }
    .addOne {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 40px;
      color: #3c76ff;
      border: 1px solid #3c76ff;
      cursor: pointer;
      margin-top: 20px;
    }
    .noticeitem {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .left {
        flex: 1;
        & > div {
          display: flex;
          align-items: center;
          width: 100%;
          .el-input {
            max-width: 300px;
          }
        }
        .left-top {
          margin-bottom: 10px;
          p {
            width: 80px;
            margin-right: 20px;
          }
        }
        .left-bot {
          padding-left: 100px;
        }
      }
      .right {
        width: 40px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
</style>
